.bg-signin {
  display: flex;
  background: url("../img/bg-2.jpg") no-repeat fixed;
  background-size: cover;
  background-position: center;
  justify-content: center;
}

.v-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  min-height: 100vh;
}

.admin-login-card {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 26rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.accept-terms-card {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30rem;
  height: 30rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.sectionForget {
  text-align: center;
  padding: 10px 20px 50px 10px;
}
.btnForget {
  text-decoration: none;
  color: #009c9f;
}
.btnForget:hover {
  color: #008385;
}

.sectionLine {
  text-align: center;
  border-bottom: 1px solid #dadada;
  line-height: 0.1em;
}
.sectionLine span {
  background: #fff;
  padding: 0 10px;
}

.btnCreate {
  border-color: #e91e63 !important;
  background-color: transparent !important;
  color: #e91e63 !important;
}

.btnCreate:hover {
  background-color: #e91e63 !important;
  color: #fff !important;
}
